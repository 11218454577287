import React from "react";
import Handle404 from "./Handle404";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logError } from "../store/actions/auth";
import Live from "../helpers/host";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    var caller_line = error?.stack?.toString()?.split("at ");
    if (Live()) {
      this.props.logError({
        user_id: this?.props?.user || null,
        error: caller_line?.[0],
        page: caller_line?.[1] + " in page " + document?.referrer,
      });
    }
  }
  render() {
    if (this.state.hasError) {
      //   return <Handle404 />;
      if (Live()) {
        window.location.replace("/something-went-wrong");
      }
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  logError(payload) {
    dispatch(logError(payload));
  },
});

// export default ErrorBoundary;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
);
