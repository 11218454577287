import { createReducer } from "../createReducerFunc";
import {
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_REQUEST_SUCCEED,
  FETCH_TOKEN_REQUEST_FAILURE,
  TOKEN_REMOVE_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCEED,
  FETCH_REFRESH_TOKEN_REQUEST, ///
  FETCH_REFRESH_TOKEN_REQUEST_SUCCEED, ///
  FETCH_REFRESH_TOKEN_REQUEST_FAILURE, ///
  UPDATE_KEEP_ME_LOGGED_IN,
  GET_CREDIT_INFO_SUCCEED,
  GET_UNLOCKED_INFO_SUCCEED,
  ADD_CREDITS_SUCCESS,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  UPDATE_CREDITS_INFO,
  REQUEST_RUNNER_CALL_SUCCSESS, ///
} from "../constants";
import cookies from "js-cookie";
import cookie from "js-cookie";
import jwtDecode from "jwt-decode"; ///

const initialState = {
  token: null,
  user: null,
  isAuthenticating: false,
  isAuthenticated: false,
  keepMeLoggedIn: true,
};

export default createReducer(initialState, {
  [FETCH_TOKEN_REQUEST]: (state) => {
    cookies.remove("token"); ///
    // cookies.remove("refresh-token"); ///
    return Object.assign({}, state, {
      token: null, ///
      user: null, ///
      isAuthenticating: true,
    });
  },
  [FETCH_TOKEN_REQUEST_SUCCEED]: (state, payload) => {
    let token = payload.access;
    let user = jwtDecode(token);
    cookies.set("token", token, {
      expires: 2110,
    });
    if (state.keepMeLoggedIn === true) {
      cookies.set("refresh-token", payload.refresh, {
        expires: 2210,
      });
    } // i think i should add    else{cookies.set("refresh-token", payload.refresh)}  for session length
    return Object.assign({}, state, {
      token: token, //payload.token,///
      user: user, //payload.user,///
      isAuthenticating: false,
      isAuthenticated: true,
    });
  },
  [FETCH_TOKEN_REQUEST_FAILURE]: (state) => {
    cookies.remove("token");
    return Object.assign({}, state, {
      token: null, //
      user: null, // if token was removed user object must be removed to avoid user showing user name (condition for logged in every where) without having a token cookie (which is the actual logged in for backend (request.usre.is_user))
      isAuthenticating: false,
    });
  },
  /////////////////////////////////////////////////////////////////////

  [FETCH_REFRESH_TOKEN_REQUEST]: (state) => {
    return Object.assign({}, state, {
      isAuthenticating: true,
    });
  },
  [FETCH_REFRESH_TOKEN_REQUEST_SUCCEED]: (state, payload) => {
    cookies.set("token", payload.access, {
      expires: 2110,
    });
    cookie.set("token", payload.access, {
      expires: 2110,
    });
    return Object.assign({}, state, {
      token: payload.access,
      isAuthenticating: false,
      isAuthenticated: true,
    });
  },
  //double check the action below, adjustments might be required later.

  [UPDATE_KEEP_ME_LOGGED_IN]: (state, payload) => {
    return Object.assign({}, state, {
      keepMeLoggedIn: payload,
    });
  },
  /////////////////////////////////////////////////////////////////////

  [TOKEN_REMOVE_REQUEST]: () => {
    cookies.remove("token");
    cookies.remove("refresh-token");
    return Object.assign({}, initialState);
  },
  // [FORGOT_PASSWORD_REQUEST]: () => {
  //   return Object.assign({}, initialState);
  // },
  // [FORGOT_PASSWORD_REQUEST_SUCCEED]: () => {
  //   return Object.assign({}, initialState);
  // },
  [GET_CREDIT_INFO_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      credits_info: payload,
    });
  },

  [GET_UNLOCKED_INFO_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      unlocked_cars_info: payload,
    });
  },
  [ADD_CREDITS_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      credits_info: payload.data,
    });
  },
  [GET_SUBSCRIPTION_PLANS_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      plans: payload,
    });
  },
  [UPDATE_CREDITS_INFO]: (state, payload) => {
    return Object.assign({}, state, {
      credits_info: payload.credits_info,
    });
  },
  [REQUEST_RUNNER_CALL_SUCCSESS]: (state, payload) => {
    let tempUnlockedInfo = [...state.unlocked_cars_info];
    let index = tempUnlockedInfo.findIndex((car) => {
      return car.car_id == payload.car;
    });
    tempUnlockedInfo[index] = Object.assign(
      {},
      { ...tempUnlockedInfo[index] },
      { call_requested: [payload] }
    );
    return Object.assign({}, state, {
      unlocked_cars_info: tempUnlockedInfo,
    });
  },
});
