import { makeStyles } from "@material-ui/core";
import React from "react";
import LoadingScreenGif from "../images/LoadingScreen.gif";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "700px",
    height: window.innerHeight,
  },
  gif: {
    width: "90vw",
    maxWidth: "400px",
  },
}));

const LoadingScreen = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <img src={LoadingScreenGif} className={classes.gif} />
    </div>
  );
};

export default LoadingScreen;
