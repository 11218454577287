import { put, takeLatest } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  FETCH_FIELDS_REQUEST,
  FETCH_FIELDS_REQUEST_SUCCEED,
  FETCH_FIELDS_REQUEST_FAILURE,
  FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED,
  FETCH_FIELDS_OPTIONS_REQUEST,
  FETCH_FIELDS_OPTIONS_REQUEST_FAILURE,
} from "../constants";

import API_URLS from "../../api";

export function* fetchFields(action) {
  try {
    let data = {
      url: API_URLS(action.payload.formId).CAR_FORM.FORM_FIELDS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_FIELDS_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({ type: FETCH_FIELDS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* fetchFieldsWatcher() {
  yield takeLatest(FETCH_FIELDS_REQUEST, fetchFields);
}

export function* fetchFieldsOptions(action) {
  try {
    let data = {
      url: API_URLS(
        `${action.payload.formId}&invokers__id=${action.payload.invoker_id}`
      ).CAR_FORM.FORM_FIELDS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED,
      payload: response.results,
    });
  } catch (e) {
    yield put({
      type: FETCH_FIELDS_OPTIONS_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* fetchFieldsOptionsWatcher() {
  yield takeLatest(FETCH_FIELDS_OPTIONS_REQUEST, fetchFieldsOptions);
}
