import {
  BOOK_APPOINTMENT,
  DEALER_JOIN_US,
  BECOME_AN_OFFICER,
  TOGGLE_PROMOTION_DISABLED,
  LOG_VISITOR_VIEW,
} from "../constants";

export function joinUsDealer(payload) {
  return {
    type: DEALER_JOIN_US,
    payload: payload,
  };
}

export function becomeAnOfficer(payload) {
  return {
    type: BECOME_AN_OFFICER,
    payload: payload,
  };
}

export function togglePromotionDisabled(payload) {
  return {
    type: TOGGLE_PROMOTION_DISABLED,
    payload: payload,
  };
}

export function logVisitorView(payload) {
  return {
    type: LOG_VISITOR_VIEW,
    payload: payload,
  };
}
