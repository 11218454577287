import { put, takeLatest, select } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_REQUEST_SUCCEED,
  FETCH_TOKEN_REQUEST_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCEED,
  FORGOT_PASSWORD_REQUEST_FAILURE,
  TOGGLE_NOTIFICATION_BAR,
  RESET_PASSWORD_CONFIRM,
  // ACTIVATE_ACCOUNT_REQUEST,
  // ACTIVATE_ACCOUNT_FAILURE,
  FETCH_REFRESH_TOKEN_REQUEST,
  FETCH_REFRESH_TOKEN_REQUEST_SUCCEED,
  FETCH_REFRESH_TOKEN_REQUEST_FAILURE,
  CHECK_UNIQUE_PHONE,
  CHECK_UNIQUE_PHONE_SUCESS,
  CHECK_UNIQUE_PHONE_FAILURE,
  SIGNUP_NEW_USER_FAILURE,
  SIGNUP_NEW_USER,
  SEND_OTP_FAILURE,
  SEND_OTP,
  VALIDATE_OTP,
  VALIDATE_OTP_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_FAILURE,
  RESET_PASSWORD_REQUEST_SUCCESS,
  UPDATE_ACCOUNT_INFO_FAILURE,
  UPDATE_ACCOUNT_INFO,
  CHECK_PASSWORD_FAILURE,
  CHECK_PASSWORD,
  GET_CREDIT_INFO,
  GET_CREDIT_INFO_SUCCEED,
  GET_CREDIT_INFO_FAILURE,
  GET_UNLOCKED_INFO,
  GET_UNLOCKED_INFO_SUCCEED,
  GET_UNLOCKED_INFO_FAILURE,
  UNLOCK_CAR,
  SUBSCRIBE_PLAN,
  SUBSCRIBE_PLAN_FAILURE,
  ADD_CREDITS,
  ADD_CREDITS_SUCCESS,
  SET_UNLOCKED,
  ERROR_LOG,
} from "../constants";

import API_URLS from "../../api";
import cookies from "js-cookie"; ///
import jwtDecode from "jwt-decode"; ///

// import {getCarsList} from "../actions/car"
export function* fetchToken(action) {
  try {
    let data = {
      url: API_URLS().AUTH.OBTAIN_TOKEN,
      method: "POST",
      body: {
        phone_number: action.payload.phone_number,
        password: action.payload.password,
      },
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_TOKEN_REQUEST_SUCCEED, payload: response });

    action.payload.callback(response);
    // const getUser = (state) =>
    //   state.auth.user.dealership_id; //this will get the account_activated status from the redux store
    // getCarsList({
    //   filter: `?published=true&listing_request__dealership=${yield select(getUser)}&is_sold=false`,
    // });
  } catch (e) {
    yield put({ type: FETCH_TOKEN_REQUEST_FAILURE, error: new Error(e) });
    action.payload.callback(false);

    // yield put({
    //   type: TOGGLE_NOTIFICATION_BAR,
    //   payload: {
    //     message: "Please make sure you entered valid credentials",
    //     variant: "error",
    //     open: true,
    //   },
    // });
  }
}
//////////////////////////////////////////////////////
export function* fetchRefreshToken() {
  try {
    let data = {
      url: API_URLS().AUTH.OBTAIN_REFRESH_TOKEN,
      method: "POST",
      body: {
        refresh: cookies.get("refresh-token"),
      },
      refreshFlag: true,
    };
    // const token = cookies.get("token");
    // const refreshToken = cookies.get("refresh-token");
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_REFRESH_TOKEN_REQUEST_SUCCEED,
      payload: response,
    });
  } catch (e) {
    yield put({
      type: FETCH_REFRESH_TOKEN_REQUEST_FAILURE,
      error: new Error(e),
    });
    // yield put({ type: TOKEN_REMOVE_REQUEST });
    // yield put(push("/login"));
  }
}
//////////////////////////////////////////////////////

export function* forgotPassword(action) {
  try {
    let data = {
      url: API_URLS().AUTH.FORGOT_PASSWORD,
      method: "POST",
      body: {
        phone: action.payload.phone,
        password: action.payload.password,
      },
    };
    //
    const response = yield requestAPI(data); //check here
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: FORGOT_PASSWORD_REQUEST_FAILURE, error: new Error(e) });
  }
  return;
}

///check phone  unique

export function* checkUniquePhone(action) {
  try {
    let body = {};
    if (action.payload?.phone) body["phone"] = action.payload.phone;
    let data = {
      url: API_URLS().AUTH.CHECK_PHONE,
      method: "POST",
      body: { ...body },
    };
    const response = yield requestAPI(data); //check here
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: CHECK_UNIQUE_PHONE_FAILURE, error: new Error(e) });
  }

  return;
}

export function* signUpNewUser(action) {
  // SEND_OTP SIGNUP_USER
  try {
    let data = {
      url: API_URLS().AUTH.SIGNUP_USER,
      method: "POST",
      // body: {phone:action.payload},
      body: { ...action.payload },
    };
    const response = yield requestAPI(data); //check here
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: SIGNUP_NEW_USER_FAILURE, error: new Error(e) });
  }

  return;
}

export function* updateAccountInfo(action) {
  // SEND_OTP SIGNUP_USER
  try {
    let data = {
      url: API_URLS().AUTH.UPDATE_INFO,
      method: "POST",
      body: { ...action.payload },
    };
    const response = yield requestAPI(data); //check here
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: UPDATE_ACCOUNT_INFO_FAILURE, error: new Error(e) });
  }

  return;
}

export function* checkPassword(action) {
  // SEND_OTP SIGNUP_USER
  try {
    let data = {
      url: API_URLS().AUTH.CHECK_PASS,
      method: "POST",
      body: { password: action.payload.password },
    };
    const response = yield requestAPI(data); //check here
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: CHECK_PASSWORD_FAILURE, error: new Error(e) });
  }

  return;
}

export function* sendOTP(action) {
  // SEND_OTP SIGNUP_USER
  try {
    let data = {
      url: API_URLS().AUTH.SEND_OTP,
      method: "POST",
      body: { phone: action.payload.payload },
    };
    const response = yield requestAPI(data); //check here
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: SEND_OTP_FAILURE, error: new Error(e) });
  }

  return;
}

export function* confirmReset(action) {
  if (action.payload.verifiedPassword !== action.payload.password) {
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Your passwords do not match!",
        variant: "warning",
        open: true,
      },
    });
    return;
  }
  try {
    let data = {
      url: API_URLS().AUTH.CONFIRM_PASSWORD,
      method: "POST",
      body: {
        activation_key: action.payload.code,
        password: action.payload.password,
      },
    };

    yield requestAPI(data);
    action.payload.callback();
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message:
          "Your password has been changed successfully, please login with your new password",
        variant: "success",
        open: true,
      },
    });

    action.payload.callback();
  } catch (e) {
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "error",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* getCredit(action) {
  try {
    let data = {
      url:
        API_URLS().ACCOUNTS.GET_CREDIT +
        `${action.payload.user_id}/get_credits`,
      method: "GET",
    };

    const response = yield requestAPI(data);

    yield put({ type: GET_CREDIT_INFO_SUCCEED, payload: response.results });
  } catch (e) {
    yield put({ type: GET_CREDIT_INFO_FAILURE, error: new Error(e) });
  }
}

export function* getCreditWatcher() {
  yield takeLatest(GET_CREDIT_INFO, getCredit);
}

export function* getUnlocked(action) {
  try {
    let data = {
      url:
        API_URLS().ACCOUNTS.UNLOKED_CARS_INFO +
        `${action.payload.user_id}/get_unlocked_cars`,
      method: "GET",
    };

    const response = yield requestAPI(data);
    yield put({ type: GET_UNLOCKED_INFO_SUCCEED, payload: response.results });
  } catch (e) {
    yield put({ type: GET_UNLOCKED_INFO_FAILURE, error: new Error(e) });
  }
}

export function* unlockCar(action) {
  try {
    let data = {
      url: API_URLS().SUBSCRIPTION_PLANS.UNLOCKCAR,
      method: "POST",
      body: {
        user_id: action.payload.user_id,
        carId: action.payload.carid,
      },
    };

    const response = yield requestAPI(data);
    action.payload.callback(response);

    if (response.msg == "success") {
      yield put({ type: SET_UNLOCKED });
      yield put({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          message: "Car Unlocked Successfully!",
          variant: "success",
          open: true,
        },
      });
    }
  } catch (e) {
    if (e.response.status == 402) {
      yield put({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          message: "Not enough credits",
          variant: "error",
          open: true,
        },
      });
    } else {
      yield put({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          message: "Something went wrong, please try again later.",
          variant: "error",
          open: true,
        },
      });
    }
    action.payload.callback();

    yield put({ type: GET_UNLOCKED_INFO_FAILURE, error: new Error(e) });
  }
}

//////////// subscribe to Plan
export function* subscribePlan(action) {
  try {
    let data = {
      url: API_URLS().SUBSCRIPTION_PLANS.SUBSCIBE,
      method: "POST",
      body: {
        user_id: action.payload.user_id,
        plan: action.payload.plan,
        refill: action.payload.refill,
      },
    };

    const response = yield requestAPI(data);
    yield put({
      type: ADD_CREDITS_SUCCESS,
      payload: {
        data: response.newSubscribtioninfo,
      },
    });
    action.payload.callback(response);
    if (response.msg == "success") {
      yield put({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          message: action.payload.message,
          variant: "success",
          open: true,
        },
      });
    }
  } catch (e) {
    yield put({ type: SUBSCRIBE_PLAN_FAILURE, error: new Error(e) });
  }
}

export function* subscribePlanWatcher() {
  yield takeLatest(SUBSCRIBE_PLAN, subscribePlan);
}

// subscribePlan

//////////
export function* addCredits(action) {
  try {
    let data = {
      url: API_URLS().SUBSCRIPTION_PLANS.ADD_CREDITS,
      method: "POST",
      body: {
        user_id: action.payload.user_id,
        plan_id: action.payload.plan_id,
        credits_cnt: action.payload.creditscnt,
      },
    };
    const response = yield requestAPI(data);
    yield put({
      type: ADD_CREDITS_SUCCESS,
      payload: {
        data: response.newSubscribtioninfo,
      },
    });
    // action.payload.callback(response);
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "CREDITS ADDED Successfully!",
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    console.log("Err", e);
    // yield put({ type: SUBSCRIBE_PLAN_FAILURE, error: new Error(e) });
  }
}

export function* addCreditsWatcher() {
  yield takeLatest(ADD_CREDITS, addCredits);
}
///////////////////

export function* unlockCarWatcher() {
  yield takeLatest(UNLOCK_CAR, unlockCar);
}

export function* getUnlockedWatcher() {
  yield takeLatest(GET_UNLOCKED_INFO, getUnlocked);
}

export function* fetchTokenWatcher() {
  yield takeLatest(FETCH_TOKEN_REQUEST, fetchToken);
}
//////////////////////////////////////////////////////

export function* fetchRefreshTokenWatcher() {
  yield takeLatest(FETCH_REFRESH_TOKEN_REQUEST, fetchRefreshToken);
}
//////////////////////////////////////////////////////

export function* forgotPasswordWatcher() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword);
}

export function* confirmResetWatcher() {
  yield takeLatest(RESET_PASSWORD_CONFIRM, confirmReset);
}

export function* checkUniquePhoneWatcher() {
  yield takeLatest(CHECK_UNIQUE_PHONE, checkUniquePhone);
}

////send otp

export function* signUpNewUserWatcher() {
  yield takeLatest(SIGNUP_NEW_USER, signUpNewUser);
}

////////

export function* updateAccountInfoWatcher() {
  yield takeLatest(UPDATE_ACCOUNT_INFO, updateAccountInfo);
}

///

export function* sendOTPWatcher() {
  yield takeLatest(SEND_OTP, sendOTP);
}

//// validate otp

export function* validateOTP(action) {
  try {
    let data = {
      url: API_URLS().AUTH.VALIDATE_OTP,
      method: "POST",
      body: { phone: action.payload.phone, otp: action.payload.otp },
    };
    const response = yield requestAPI(data);
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: VALIDATE_OTP_FAILURE, error: new Error(e) });
  }

  return;
}

export function* validateOTPWatcher() {
  yield takeLatest(VALIDATE_OTP, validateOTP);
}

export function* resetPassword(action) {
  try {
    let data = {
      url: API_URLS().AUTH.RESET_PASSWORD,
      method: "POST",
      body: { password: action.payload.password },
    };
    const response = yield requestAPI(data);
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: RESET_PASSWORD_REQUEST_FAILURE, error: new Error(e) });
  }

  return;
}

export function* resetPasswordWatcher() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
}

export function* checkPasswordWatcher() {
  yield takeLatest(CHECK_PASSWORD, checkPassword);
}

export function* logError(action) {
  try {
    let data = {
      url: API_URLS().AUTH.ERROR_LOG,
      method: "POST",
      body: {
        car_id: action?.payload?.car_id,
        user_id: action?.payload?.user_id,
        error: action?.payload?.error,
        page: action?.payload?.page,
      },
    };
    const response = yield requestAPI(data);
    // window.location.replace("/something-went-wrong");
  } catch (e) {}

  return;
}

export function* logErrorWatcher() {
  yield takeLatest(ERROR_LOG, logError);
}
