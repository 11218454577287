import { createReducer } from "../createReducerFunc";
import {
  CREATE_EMPTY_CAR_REQUEST_SUCCEED,
  CREATE_EMPTY_CAR_REQUEST,
  CREATE_EMPTY_CAR_REQUEST_FAILURE,
  FETCH_CAR_REQUEST,
  FETCH_CAR_REQUEST_SUCCEED,
  FETCH_CAR_REQUEST_FAILURE,
  LIST_CARS_REQUEST,
  LIST_CARS_REQUEST_FAILURE,
  LIST_CARS_REQUEST_END_OF_RESULTS,
  LIST_CARS_REQUEST_SUCCEED,
  ADD_CAR,
  REMOVE_CAR,
  CLEAR_CAR_LIST,
  // BOOK_APPOINTMENT_SUCCESS,
  // EDIT_APPOINTMENT_SUCCESS,
  GET_UNLOCKED_INFO_SUCCEED,
  SET_UNLOCKED,
  REQUEST_RUNNER_CALL_SUCCSESS,
  UNLOCKED_PART_SUCCEED,
  CLEAR_UNLOCKED_PART,
} from "../constants";

const initialState = {
  car: {},
  fetchingCar: true,
  cars: [],
  fetching: true,
  finalPage: false,
  noOfListings: 0,
  unlocked_part: {},
};

export default createReducer(initialState, {
  [LIST_CARS_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetching: true,
    });
  },
  [LIST_CARS_REQUEST_SUCCEED]: (state, payload) => {
    let pageCarList = payload.results;
    let isFinal = payload.next == null;
    return Object.assign({}, state, {
      // cars: [...state.cars, ...payload],
      // cars: payload,
      cars:
        payload.previous == null // if page == 1 remove past cars else combine cars // error used to happen if you go from search (home page) to vlp before home's cars arrive it would add 9 cars from home to new cars and cause duplication
          ? [...pageCarList]
          : [...new Set([...state.cars, ...pageCarList])], /// check probably doesnot work
      finalPage: isFinal,
      noOfListings: payload.count,
      fetching: false,
    });
  },
  [CLEAR_CAR_LIST]: (state) => {
    return Object.assign({}, state, {
      cars: [],
      finalPage: false,
      noOfListings: 0,
    });
  },
  [LIST_CARS_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetching: false,
    });
  },
  [LIST_CARS_REQUEST_END_OF_RESULTS]: (state) => {
    return Object.assign({}, state, {
      fetching: false,
      // finalPage: true,
    });
  },
  [FETCH_CAR_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetchingCar: true,
    });
  },
  [FETCH_CAR_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      car: payload,
      fetchingCar: false,
    });
  },
  [FETCH_CAR_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetchingCar: false,
    });
  },
  [ADD_CAR]: (state, payload) => {
    const modifiedCars = state.cars;
    modifiedCars.push(payload.instance);
    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
  [REMOVE_CAR]: (state, payload) => {
    const removed = payload.instance;
    const modifiedCars = state.cars;
    modifiedCars.forEach((element, index) => {
      if (element.id === removed) {
        modifiedCars.splice(index, 1);
      }
    });
    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
  // [BOOK_APPOINTMENT_SUCCESS]: (state, payload) => {
  //   return Object.assign({}, state, {
  //     car: { ...state.car, booked: true },
  //   });
  // },

  // [EDIT_APPOINTMENT_SUCCESS]: (state, payload) => {
  //   return Object.assign({}, state, {
  //     car: { ...state.car, booked: true },
  //   });
  // },
  [SET_UNLOCKED]: (state, payload) => {
    return Object.assign({}, state, {
      car: { ...state.car, unlocked: true },
    });
  },
  [REQUEST_RUNNER_CALL_SUCCSESS]: (state, payload) => {
    let tempCar = { ...state.car };
    tempCar.runner_data.call_requested = payload;
    return Object.assign({}, state, {
      car: { ...tempCar },
    });
  },
  [UNLOCKED_PART_SUCCEED]: (state, payload) => {
    console.log("dddddddddddd", payload);
    return Object.assign({}, state, {
      unlocked_part: payload,
    });
  },
  [CLEAR_UNLOCKED_PART]: (state) => {
    return Object.assign({}, state, {
      unlocked_part: {},
    });
  },
});
