import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  LOG_CONFERENCE_CALL,
  LOG_VDP_VIEW,
  LOG_CAR_CARD_VIEW,
} from "../constants";

import API_URLS from "../../api";

export function* logConferenceCall(action) {
  try {
    let data = {
      url: API_URLS().METRICS.CONFERENCE,
      method: "POST",
      body: { ...action.payload },
    };
    const response = yield requestAPI(data);
  } catch (e) {}
}

export function* logConferenceCallWatcher() {
  yield takeEvery(LOG_CONFERENCE_CALL, logConferenceCall);
}

export function* logVdpView(action) {
  try {
    let data = {
      url: API_URLS().METRICS.VDP,
      method: "POST",
      body: { ...action.payload },
    };
    const response = yield requestAPI(data);
  } catch (e) {}
}

export function* logVdpViewWatcher() {
  yield takeEvery(LOG_VDP_VIEW, logVdpView);
}

export function* logCarCardView(action) {
  try {
    let data = {
      url: API_URLS().METRICS.CARD,
      method: "POST",
      body: { ...action.payload },
    };
    const response = yield requestAPI(data);
  } catch (e) {}
}

export function* logCarCardViewWatcher() {
  yield takeEvery(LOG_CAR_CARD_VIEW, logCarCardView);
}
