export default function Live() {
  if (window.location.hostname === "markabte.com") {
    return true;
  } else {
    return false;
  }
}

export function GTR() {
  if (window.location.hostname === "gtr.markabte.com") {
    return true;
  } else {
    return false;
  }
}
