import React from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import App from "./containers/App";
import theme from "./theme";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import moment from "moment";
import "moment/locale/ar";
// import TagManager from "react-gtm-module";

// const tagManagerArgs = {
//   gtmId: "GTM-5F3Q6CG",
//   auth: "sICgZlHnWQ6A8jMdDgPl8w",
//   preview: "env-3",
// };

// TagManager.initialize(tagManagerArgs);
// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
moment.locale(localStorage.getItem("language")); // returns the new locale based on localstorage language, either 'en' or 'ar'

function Main() {
  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={<LinearProgress />} persistor={persistor}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default Main;
