import { all } from "redux-saga/effects";
import {
  fetchTokenWatcher,
  fetchRefreshTokenWatcher,
  forgotPasswordWatcher,
  confirmResetWatcher,
  checkUniquePhoneWatcher,
  signUpNewUserWatcher,
  sendOTPWatcher,
  validateOTPWatcher,
  resetPasswordWatcher,
  checkPasswordWatcher,
  updateAccountInfoWatcher,
  getCreditWatcher,
  getUnlockedWatcher,
  unlockCarWatcher,
  subscribePlanWatcher,
  addCreditsWatcher,
  logErrorWatcher,
} from "./sagas-definitions/auth";
import {
  getCarPriceFormWatcher,
  getCarListWatcher,
  getCarDetailsWatcher,
  getCarGroupsWatcher,
  getCarWatcher,
  getCarVisualizationWatcher,
  fetchFieldOptionsWatcher,
  reportCarPurchaseWatcher,
  getCarDetailsImagesWatcher,
  simpleCarDataWatcher,
  VHCarInfoWatcher,
  CarFeaturesWatcher,
  getCarVisualizationIDsWatcher,
  getUnlockedPartWatcher,
  approveQcCarWatcher,
  requestCallBackWatcher,
} from "./sagas-definitions/car";
// import { getApptIntervalWatcher } from "./sagas-definitions/dealership";
import {
  callDealershipAnonymouslyWatcher,
  getDealerHoursWatcher,
  requestRunnerCallWatcher,
  requestWhatsAppMessageWatcher,
  submitSellMyCarDataWatcher,
} from "./sagas-definitions/dealership";
// import {
//   appoinmentRemovedWatcher,
//   bookAppointmentWatcher,
//   editAppointmentWatcher,
//   bookedSeenWatcher,
//   getAppointmentsWatcher,
//   setAppointmentRemovedWatcher,
//   setUserAttendedWatcher,
//   setUserRemovedWatcher,
//   getSoldAppointmentsWatcher,
//   resetSoldAppointmentsWatcher,
//   getDeactivatedCarsWatcher,
// } from "./sagas-definitions/appointments";
import { createRequestsWatcher } from "./sagas-definitions/request";
import {
  fetchFieldsWatcher,
  fetchFieldsOptionsWatcher,
} from "./sagas-definitions/forms";
import {
  updatePageTimeStampWatcher,
  getPageTimeStampsWatcher,
  getUnseenNotificationsWatcher,
} from "./sagas-definitions/websockets";
import { listPlansWatcher } from "./sagas-definitions/subscriptionPlans";

import { requestConnectionWatcher } from "./sagas-definitions/websockets";
import { updateAccountInfo } from "./actions/auth";
// import { appt, setUserAttended } from "./actions/appointments";
import {
  joinUsDealerWatcher,
  becomeAnOfficerWatcher,
  logVisitorViewWatcher,
} from "./sagas-definitions/accounts";
import {
  createSessionWatcher,
  createCheckoutSessionWatcher,
  requestPayWatcher,
  // updateSessionAmmountWatcher,
  updatePaymentResultsWatcher,
} from "./sagas-definitions/payment";

import {
  logConferenceCallWatcher,
  logVdpViewWatcher,
  logCarCardViewWatcher,
} from "./sagas-definitions/metrics";

import {
  getCitiesWatcher,
  getAreasWatcher,
  getMakeWatcher,
  getModelsWatcher,
  getAvailableAppointmentsWatcher,
  getYearsWatcher,
  getInspectionPackagesWatcher,
  addAppointmentWatcher,
  getAvailableTimeSlotsWatcher,
  bookAppointmentWatcher,
} from "./sagas-definitions/timeSlots";

function* appSagas() {
  yield all([
    getCarGroupsWatcher(),
    getCarDetailsWatcher(),
    getCarListWatcher(),
    fetchTokenWatcher(),
    fetchRefreshTokenWatcher(),
    forgotPasswordWatcher(),
    confirmResetWatcher(),
    createRequestsWatcher(),
    getCarPriceFormWatcher(),
    fetchFieldsWatcher(),
    getCarWatcher(),
    getCarVisualizationWatcher(),
    requestConnectionWatcher(),
    updatePageTimeStampWatcher(),
    getPageTimeStampsWatcher(),
    getUnseenNotificationsWatcher(),
    fetchFieldsOptionsWatcher(),
    fetchFieldOptionsWatcher(),
    checkUniquePhoneWatcher(),
    sendOTPWatcher(),
    signUpNewUserWatcher(),
    validateOTPWatcher(),
    resetPasswordWatcher(),
    checkPasswordWatcher(),
    updateAccountInfoWatcher(),
    // getApptIntervalWatcher(),
    // bookAppointmentWatcher(),
    // getAppointmentsWatcher(),
    // setUserRemovedWatcher(),
    // bookedSeenWatcher(),
    // getSoldAppointmentsWatcher(),
    // resetSoldAppointmentsWatcher(),
    // editAppointmentWatcher(),
    // setAppointmentRemovedWatcher(),
    // setUserAttendedWatcher(),
    reportCarPurchaseWatcher(),
    becomeAnOfficerWatcher(),
    joinUsDealerWatcher(),
    // getDeactivatedCarsWatcher(),
    getCreditWatcher(),
    getUnlockedWatcher(),
    listPlansWatcher(),
    unlockCarWatcher(),
    subscribePlanWatcher(),
    addCreditsWatcher(),
    callDealershipAnonymouslyWatcher(),
    getDealerHoursWatcher(),
    createSessionWatcher(),
    createCheckoutSessionWatcher(),
    requestPayWatcher(),
    // updateSessionAmmountWatcher(),
    requestRunnerCallWatcher(),
    requestWhatsAppMessageWatcher(),
    updatePaymentResultsWatcher(),
    logConferenceCallWatcher(),
    logVdpViewWatcher(),
    logCarCardViewWatcher(),
    logErrorWatcher(),
    submitSellMyCarDataWatcher(),
    getCarDetailsImagesWatcher(),
    simpleCarDataWatcher(),
    VHCarInfoWatcher(),
    CarFeaturesWatcher(),
    getUnlockedPartWatcher(),
    logVisitorViewWatcher(),
    getCitiesWatcher(),
    getAreasWatcher(),
    getMakeWatcher(),
    getModelsWatcher(),
    getAvailableAppointmentsWatcher(),
    getYearsWatcher(),
    getInspectionPackagesWatcher(),
    addAppointmentWatcher(),
    getAvailableTimeSlotsWatcher(),
    bookAppointmentWatcher(),
    approveQcCarWatcher(),
    requestCallBackWatcher(),
  ]);
}

export default appSagas;
