import { put, takeLatest } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  CREATE_SESSION,
  CREATE_SESSION_SUCCSESS,
  CREATE_SESSION_FAILURE,
  REQUEST_PAY,
  REQUEST_PAY_SUCCSESS,
  REQUEST_PAY_FAILURE,
  CREATE_CHECKOUT_SESSION,
  CREATE_CHECKOUT_SESSION_FAILURE,
  CREATE_CHECKOUT_SESSION_SUCCSESS,
  TOGGLE_NOTIFICATION_BAR,
  UPDATE_PAYMENT_RESULTS,
  UPDATE_PAYMENT_RESULTS_SUCCESS,
  UPDATE_PAYMENT_RESULTS_FAILURE,
} from "../constants";
import API_URLS from "../../api";

export function* createSession(action) {
  try {
    let data = {
      url: API_URLS().PAYMENT.CREATE_SESSION,
      method: "POST",
      body: {
        amount: action.payload.amount,
        item: action.payload.item,
      },
    };

    const response = yield requestAPI(data);
    yield put({ type: CREATE_SESSION_SUCCSESS, payload: response.session_id });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: CREATE_SESSION_FAILURE, payload: new Error(e) });
  }
}

export function* createSessionWatcher() {
  yield takeLatest(CREATE_SESSION, createSession);
}

export function* createCheckoutSession(action) {
  try {
    let data = {
      url: API_URLS().PAYMENT.CREATE_CHECKOUT_SESSION,
      method: "POST",
      body: {
        amount: action.payload.amount,
        item: action.payload.item,
      },
    };

    const response = yield requestAPI(data);
    yield put({ type: CREATE_CHECKOUT_SESSION_SUCCSESS, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: CREATE_CHECKOUT_SESSION_FAILURE, payload: new Error(e) });
  }
}

export function* createCheckoutSessionWatcher() {
  yield takeLatest(CREATE_CHECKOUT_SESSION, createCheckoutSession);
}

export function* requestPay(action) {
  try {
    let data = {
      url: API_URLS().PAYMENT.PAY,
      method: "POST",
      body: {
        order_id: action.payload.orderId,
        session_id: action.payload.sessionId,
        amount: action.payload.amount,
        scheme: action.payload.scheme,
      },
    };

    const response = yield requestAPI(data);

    yield put({ type: REQUEST_PAY_SUCCSESS, payload: response.session_id });
    action.payload.callback(response);
  } catch (e) {
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Something went wrong, please try again.",
        variant: "error",
        open: true,
      },
    });
    yield put({ type: REQUEST_PAY_FAILURE, payload: new Error(e) });
    action.payload.callback(e);
  }
}

export function* requestPayWatcher() {
  yield takeLatest(REQUEST_PAY, requestPay);
}

export function* updatePaymentResults(action) {
  try {
    let data = {
      url: API_URLS().PAYMENT.UPDATE_PAYMENT_RESULTS,
      method: "GET",
    };

    const response = yield requestAPI(data);

    yield put({ type: UPDATE_PAYMENT_RESULTS_SUCCESS });
  } catch (e) {
    yield put({ type: UPDATE_PAYMENT_RESULTS_FAILURE, payload: new Error(e) });
  }
}

export function* updatePaymentResultsWatcher() {
  yield takeLatest(UPDATE_PAYMENT_RESULTS, updatePaymentResults);
}
