const colors = {
  neutral: {
    white: "#fff",
    black: "#000",
    grey: "#EAEAEA",
    silver: "#bdbdbd",
  },
  primary: {
    first: "#0c6885",
    second: "#f15a29",
  },
  secondary: {
    first: "#D6F6DD",
    second: "#CF9EAC",
  },
  danger: {
    first: "#D11A2A",
    second: "#CF9EAC",
  },
  gradient: {
    primary: ["#8CA68E", "#8B9242"],
    secondary: ["#596D7D", "#453B39"],
  },
  cssGradient: {
    primary: "linear-gradient(180deg, #E3B147 25%, #E3B147 100%)",
    secondary: "linear-gradient(135deg, #254A6E 25%, #254A6E 100%)",
  },
};

export default colors;
