// AUTH Constants
export const FETCH_TOKEN_REQUEST = "FETCH_TOKEN_REQUEST";
export const FETCH_TOKEN_REQUEST_SUCCEED = "FETCH_TOKEN_REQUEST_SUCCEED";
export const FETCH_TOKEN_REQUEST_FAILURE = "FETCH_TOKEN_REQUEST_FAILURE";

export const FETCH_REFRESH_TOKEN_REQUEST = "FETCH_REFRESH_TOKEN_REQUEST";
export const FETCH_REFRESH_TOKEN_REQUEST_SUCCEED =
  "FETCH_REFRESH_TOKEN_REQUEST_SUCCEED";
export const FETCH_REFRESH_TOKEN_REQUEST_FAILURE =
  "FETCH_REFRESH_TOKEN_REQUEST_FAILURE";

export const UPDATE_KEEP_ME_LOGGED_IN = "UPDATE_KEEP_ME_LOGGED_IN";

// export const ACTIVATE_ACCOUNT_REQUEST = "ACTIVATE_ACCOUNT_REQUEST";
// export const ACTIVATE_ACCOUNT_SUCCEED = "ACTIVATE_ACCOUNT_SUCCEED";
// export const ACTIVATE_ACCOUNT_FAILURE = "ACTIVATE_ACCOUNT_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_REQUEST_SUCCEED =
  "FORGOT_PASSWORD_REQUEST_SUCCEED";
export const FORGOT_PASSWORD_REQUEST_FAILURE =
  "FORGOT_PASSWORD_REQUEST_FAILURE";
export const RESET_PASSWORD_CONFIRM = "RESET_PASSWORD_CONFIRM";

export const TOKEN_REMOVE_REQUEST = "TOKEN_REMOVE_REQUEST";

export const UPDATE_CREDITS_INFO = "UPDATE_CREDITS_INFO";

// NOTIFICATIONS Constants
export const TOGGLE_NOTIFICATION_BAR = "TOGGLE_NOTIFICATION_BAR";

// BACKDROP Constants
export const TOGGLE_LOADING_BACKDROP = "TOGGLE_LOADING_BACKDROP";

// FORMS Constants
export const FETCH_FORMS_REQUEST = "FETCH_FORMS_REQUEST";
export const FETCH_FORMS_REQUEST_SUCCEED = "FETCH_FORMS_REQUEST_SUCCEED";
export const FETCH_FORMS_REQUEST_FAILURE = "FETCH_FORMS_REQUEST_FAILURE";

export const FETCH_FIELDS_REQUEST = "FETCH_FIELDS_REQUEST";
export const FETCH_FIELDS_REQUEST_SUCCEED = "FETCH_FIELDS_REQUEST_SUCCEED";
export const FETCH_FIELDS_REQUEST_FAILURE = "FETCH_FIELDS_REQUEST_FAILURE";

export const FETCH_FEATURES_REQUEST = "FETCH_FEATURES_REQUEST";
export const FETCH_FEATURES_REQUEST_SUCCEED = "FETCH_FEATURES_REQUEST_SUCCEED";
export const FETCH_FEATURES_REQUEST_FAILURE = "FETCH_FEATURES_REQUEST_FAILURE";

export const UPDATE_ANSWERS = "UPDATE_ANSWERS";
export const UPDATE_FOEMFIELDS = "UPDATE_FOEMFIELDS";
export const UPDATE_HIDDEN = "UPDATE_HIDDEN";
export const UPDATE_FEATURES = "UPDATE_FEATURES";
export const UPDATE_POINTS = "UPDATE_POINTS";

export const FETCH_FIELDS_OPTIONS_REQUEST = "FETCH_FIELDS_OPTIONS_REQUEST";
export const FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED =
  "FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED";
export const FETCH_FIELDS_OPTIONS_REQUEST_FAILURE =
  "FETCH_FIELDS_OPTIONS_REQUEST_FAILURE";

// CAR Constants

export const LIST_CARS_REQUEST = "LIST_CARS_REQUEST";
export const LIST_CARS_REQUEST_SUCCEED = "LIST_CARS_REQUEST_SUCCEED";
export const LIST_CARS_REQUEST_FAILURE = "LIST_CARS_REQUEST_FAILURE";
export const LIST_CARS_REQUEST_END_OF_RESULTS =
  "LIST_CARS_REQUEST_END_OF_RESULTS";

export const CLEAR_CAR_LIST = "CLEAR_CAR_LIST";

export const CREATE_EMPTY_CAR_REQUEST = "CREATE_EMPTY_CAR_REQUEST";
export const CREATE_EMPTY_CAR_REQUEST_SUCCEED =
  "CREATE_EMPTY_CAR_REQUEST_SUCCEED";
export const CREATE_EMPTY_CAR_REQUEST_FAILURE =
  "CREATE_EMPTY_CAR_REQUEST_FAILURE";

export const UPDATE_CAR_FORM_REQUEST = "UPDATE_CAR_FORM_REQUEST";
export const UPDATE_CAR_FORM_REQUEST_SUCCEED =
  "UPDATE_CAR_FORM_REQUEST_SUCCEED";
export const UPDATE_CAR_FORM_REQUEST_FAILURE =
  "UPDATE_CAR_FORM_REQUEST_FAILURE";

export const FETCH_CAR_REQUEST = "FETCH_CAR_REQUEST";
export const FETCH_CAR_REQUEST_SUCCEED = "FETCH_CAR_REQUEST_SUCCEED";
export const FETCH_CAR_REQUEST_FAILURE = "FETCH_CAR_REQUEST_FAILURE";

export const FETCH_CAR_FIELDS_REQUEST = "FETCH_CAR_FIELDS_REQUEST";
export const FETCH_CAR_FIELDS_REQUEST_SUCCEED =
  "FETCH_CAR_FIELDS_REQUEST_SUCCEED";
export const FETCH_CAR_FIELDS_REQUEST_FAILURE =
  "FETCH_CAR_FIELDS_REQUEST_FAILURE";

export const FETCH_CAR_FORMS_REQUEST = "FETCH_CAR_FORMS_REQUEST";
export const FETCH_CAR_FORMS_REQUEST_SUCCEED =
  "FETCH_CAR_FORMS_REQUEST_SUCCEED";
export const FETCH_CAR_FORMS_REQUEST_FAILURE =
  "FETCH_CAR_FORMS_REQUEST_FAILURE";

export const FETCH_CAR_FEATURES_REQUEST = "FETCH_CAR_FEATURES_REQUEST";
export const FETCH_CAR_FEATURES_REQUEST_SUCCEED =
  "FETCH_CAR_FEATURES_REQUEST_SUCCEED";
export const FETCH_CAR_FEATURES_REQUEST_FAILURE =
  "FETCH_CAR_FEATURES_REQUEST_FAILURE";

export const FETCH_CAR_VISUALIZATION_REQUEST =
  "FETCH_CAR_VISUALIZATION_REQUEST";
export const FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED =
  "FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED";
export const FETCH_CAR_VISUALIZATION_REQUEST_FAILURE =
  "FETCH_CAR_VISUALIZATION_REQUEST_FAILURE";

export const FETCH_CAR_VISUALIZATION_REQUEST_IDs =
  "FETCH_CAR_VISUALIZATION_REQUEST_IDs";
export const FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED_IDs =
  "FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED_IDs";
export const FETCH_CAR_VISUALIZATION_REQUEST_FAILURE_IDs =
  "FETCH_CAR_VISUALIZATION_REQUEST_FAILURE_IDs";

export const FETCH_CAR_DETAILS_REQUEST = "FETCH_CAR_DETAILS_REQUEST";
export const FETCH_CAR_DETAILS_REQUEST_SUCCEED =
  "FETCH_CAR_DETAILS_REQUEST_SUCCEED";
export const FETCH_CAR_DETAILS_REQUEST_FAILURE =
  "FETCH_CAR_DETAILS_REQUEST_FAILURE";

export const FETCH_CAR_DETAILS_IMAGES_REQUEST =
  "FETCH_CAR_DETAILS_IMAGES_REQUEST";
export const FETCH_CAR_DETAILS_IMAGES_REQUEST_SUCCEED =
  "FETCH_CAR_DETAILS_IMAGES_REQUEST_SUCCEED";
export const FETCH_CAR_DETAILS_IMAGES_REQUEST_FAILURE =
  "FETCH_CAR_DETAILS_IMAGES_REQUEST_FAILURE";

export const FETCH_CAR_GROUPS_REQUEST = "FETCH_CAR_GROUPS_REQUEST";
export const FETCH_CAR_GROUPS_REQUEST_SUCCEED =
  "FETCH_CAR_GROUPS_REQUEST_SUCCEED";
export const FETCH_CAR_GROUPS_REQUEST_FAILURE =
  "FETCH_CAR_GROUPS_REQUEST_FAILURE";

export const REPORT_CAR_PURCHASE_REQUEST = "REPORT_CAR_PURCHASE_REQUEST";
export const REPORT_CAR_PURCHASE_REQUEST_SUCCEED =
  "REPORT_CAR_PURCHASE_REQUEST_SUCCEED";
export const REPORT_CAR_PURCHASE_REQUEST_FAILURE =
  "REPORT_CAR_PURCHASE_REQUEST_FAILURE";

export const GET_SIMPLE_CAR_DATA = "GET_SIMPLE_CAR_DATA";
export const GET_SIMPLE_CAR_DATA_SUCCEED = "GET_SIMPLE_CAR_DATA_SUCCEED";
export const GET_SIMPLE_CAR_DATA_FAILURE = "GET_SIMPLE_CAR_DATA_FAILURE";

export const GET_VH_INFO = "GET_VH_INFO";
export const GET_VH_INFO_SUCCEED = "GET_VH_INFO_SUCCEED";
export const GET_VH_INFO_FAILURE = "GET_VH_INFO_FAILURE";

export const GET_CAR_FEATURES = "GET_CAR_FEATURES";
export const GET_CAR_FEATURES_SUCCEED = "GET_CAR_FEATURES_SUCCEED";
export const GET_CAR_FEATURES_FAILURE = "GET_CAR_FEATURES_FAILURE";

export const FETCH_FIELD_OPTIONS_REQUEST = "FETCH_FIELD_OPTIONS_REQUEST";
export const FETCH_FIELD_OPTIONS_REQUEST_SUCCEED =
  "FETCH_FIELD_OPTIONS_REQUEST_SUCCEED";
export const FETCH_FIELD_OPTIONS_REQUEST_FAILURE =
  "FETCH_FIELD_OPTIONS_REQUEST_FAILURE";

export const REQUEST_CALLBACK = "REQUEST_CALLBACK";
export const REQUEST_CALLBACK_SUCCEED = "REQUEST_CALLBACK_SUCCEED";
export const REQUEST_CALLBACK_FAILURE = "REQUEST_CALLBACK_FAILURE";

// DEALERSHIP Listing req. Constants

export const CREATE_LISTING_REQUESTS = "CREATE_LISTING_REQUESTS";
export const CREATE_LISTING_REQUESTS_SUCCEED =
  "CREATE_LISTING_REQUESTS_SUCCEED";
export const CREATE_LISTING_REQUESTS_FAILURE =
  "CREATE_LISTING_REQUESTS_FAILURE";

//  websockets constants
// ws:// connection constants
export const CONNECTION_REQUEST = "CONNECTION_REQUEST";
export const CONNECTION_REQUEST_SUCCEED = "CONNECTION_REQUEST_SUCCEED";
export const CONNECTION_REQUEST_FAILURE = "CONNECTION_REQUEST_FAILURE";
// http:// constants
export const UPDATE_PAGE_TIME_STAMP = "UPDATE_PAGE_TIME_STAMP";
export const UPDATE_PAGE_TIME_STAMP_SUCCEED = "UPDATE_PAGE_TIME_STAMP_SUCCEED";
export const UPDATE_PAGE_TIME_STAMP_FAILURE = "UPDATE_PAGE_TIME_STAMP_FAILURE";

export const FETCH_PAGE_TIME_STAMP = "FETCH_PAGE_TIME_STAMP";
export const FETCH_PAGE_TIME_STAMP_SUCCEED = "FETCH_PAGE_TIME_STAMP_SUCCEED";
export const FETCH_PAGE_TIME_STAMP_FAILURE = "FETCH_PAGE_TIME_STAMP_FAILURE";

export const FETCH_UNSEEN_NOTIFICATIONS = "FETCH_UNSEEN_NOTIFICATIONS";
export const FETCH_UNSEEN_NOTIFICATIONS_SUCCEED =
  "FETCH_UNSEEN_NOTIFICATIONS_SUCCEED";
export const FETCH_UNSEEN_NOTIFICATIONS_FAILURE =
  "FETCH_UNSEEN_NOTIFICATIONS_FAILURE";

// redux store constants
export const ADD_CAR = "ADD_CAR";
export const REMOVE_CAR = "REMOVE_CAR";
export const SOLD_CAR = "SOLD_CAR";
// export const SOLD_APPOINTMENT = "SOLD_APPOINTMENT";
// export const ELAPSED_APPOINTMENT = "ELAPSED_APPOINTMENT";
export const CLEAR_SOLD_CARS = "CLEAR_SOLD_CARS";

export const CLEAR_NEW_CARS = "CLEAR_NEW_CARS";
export const CLEAR_REMOVED_CARS = "CLEAR_REMOVED_CARS";

//phone sagas
export const CHECK_UNIQUE_PHONE = "CHECK_UNIQUE_PHONE";
export const CHECK_UNIQUE_PHONE_SUCCESS = "CHECK_UNIQUE_PHONE_SUCESS";
export const CHECK_UNIQUE_PHONE_FAILURE = "CHECK_UNIQUE_PHONE_FAILURE";

/// send otp
export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";

/// VALIDATE otp
export const VALIDATE_OTP = "VALIDATE_OTP";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";

///REGTER USER
export const SIGNUP_NEW_USER = "SIGNUP_NEW_USER";
export const SIGNUP_NEW_USER_FAILURE = "SIGNUP_NEW_USER_FAILURE";
export const SIGNUP_NEW_USER_SUCCESS = "SIGNUP_NEW_USER_SUCCESS";

//TEST
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_FAILURE = "RESET_PASSWORD_REQUEST_FAILURE";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";

export const UPDATE_ACCOUNT_INFO = "UPDATE_ACCOUNT_INFO";
export const UPDATE_ACCOUNT_INFO_FAILURE = "UPDATE_ACCOUNT_INFO_FAILURE";
export const UPDATE_ACCOUNT_INFO_SUCCESS = "UPDATE_ACCOUNT_INFO_SUCCESS";

export const CHECK_PASSWORD = "CHECK_PASSWORD";
export const CHECK_PASSWORD_FAILURE = "CHECK_PASSWORD_FAILURE";
export const CHECK_PASSWORD_SUCCESS = "CHECK_PASSWORD_SUCCESS";

// ACCOUNTS
export const TOGGLE_PROMOTION_DISABLED = "TOGGLE_PROMOTION_DISABLED";

// APPOINTMENTS
// appointment available times (three days)
// export const GET_APPOINTMENT_INTERVAL = "GET_APPOINTMENT_INTERVAL";
// export const GET_APPOINTMENT_INTERVAL_FAILURE =
//   "GET_APPOINTMENT_INTERVAL_FAILURE";
// export const GET_APPOINTMENT_INTERVAL_SUCCESS =
//   "GET_APPOINTMENT_INTERVAL_SUCCESS";

// DEALER HOURS
export const GET_DEALER_HOURS = "GET_DEALER_HOURS";
export const GET_DEALER_HOURS_FAILURE = "GET_DEALER_HOURS_FAILURE";
export const GET_DEALER_HOURS_SUCCESS = "GET_DEALER_HOURS_SUCCESS";

// book appointment create
// export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT";
// export const BOOK_APPOINTMENT_FAILURE = "BOOK_APPOINTMENT_FAILURE";
// export const BOOK_APPOINTMENT_SUCCESS = "BOOK_APPOINTMENT_SUCCESS";

// edit scheduled appointment
// export const EDIT_APPOINTMENT = "EDIT_APPOINTMENT";
// export const EDIT_APPOINTMENT_FAILURE = "EDIT_APPOINTMENT_FAILURE";
// export const EDIT_APPOINTMENT_SUCCESS = "EDIT_APPOINTMENT_SUCCESS";

export const BOOKED_SEEN = "BOOKED_SEEN";

// get scheduled appointments
// export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
// export const GET_APPOINTMENTS_FAILURE = "GET_APPOINTMENTS_FAILURE";
// export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";

// ser user removed
export const SET_USER_REMOVED = "SET_USER_REMOVED";
export const SET_USER_REMOVED_FAILURE = "SET_USER_REMOVED_FAILURE";
export const SET_USER_REMOVED_SUCCESS = "SET_USER_REMOVED_SUCCESS";

// export const SET_ATTENDED_COUNT_SUCCESS = "SET_ATTENDED_COUNT_SUCCESS";

// export const SET_APPOINTMENT_REMOVED = "SET_APPOINTMENT_REMOVED";
// export const SET_APPOINTMENT_REMOVED_FAILURE =
//   "SET_APPOINTMENT_REMOVED_FAILURE";
// export const SET_APPOINTMENT_REMOVED_SUCCESS =
//   "SET_APPOINTMENT_REMOVED_SUCCESS";

// export const NOTIFY_BOOKED = "GET_APPOINTMENTS";
// export const NOTIFY_BOOKED_FAILURE = "GET_APPOINTMENTS_FAILURE";
// export const NOTIFY_BOOKED_SUCCESS = "GET_APPOINTMENTS_SUCCESS";

// get sold appointments
// export const GET_SOLD_APPOINTMENTS = "GET_SOLD_APPOINTMENTS";
// export const GET_SOLD_APPOINTMENTS_FAILURE = "GET_SOLD_APPOINTMENTS_FAILURE";
// export const GET_SOLD_APPOINTMENTS_SUCCESS = "GET_SOLD_APPOINTMENTS_SUCCESS";

// reset sold appointments
// export const RESET_SOLD_APPOINTMENTS = "RESET_SOLD_APPOINTMENTS";
// export const RESET_SOLD_APPOINTMENTS_FAILURE =
//   "RESET_SOLD_APPOINTMENTS_FAILURE";
// export const RESET_SOLD_APPOINTMENTS_SUCCESS =
//   "RESET_SOLD_APPOINTMENTS_SUCCESS";
// export const USER_ATTENDED = "USER_ATTENDED";
// export const USER_ATTENDED_FAILURE = "USER_ATTENDED_FAILURE";
// export const USER_ATTENDED_SUCCESS = "USER_ATTENDED_SUCCESS";

// become an officer
export const BECOME_AN_OFFICER = "BECOME_AN_OFFICER";

// join our network dealer
export const DEALER_JOIN_US = "DEALER_JOIN_US";
export const DEALER_JOIN_US_FAILURE = "DEALER_JOIN_US_FAILURE";
export const DEALER_JOIN_US_SUCCESS = "DEALER_JOIN_US_SUCCESS";

export const GET_DEACTIVATED_CARS = "GET_DEACTIVATED_CARS";
export const GET_DEACTIVATED_CARS_SUCCESS = "GET_DEACTIVATED_CARS_SUCCESS";
export const GET_DEACTIVATED_CARS_FAILURE = "GET_DEACTIVATED_CARS_FAILURE";

export const GET_CREDIT_INFO = "GET_CREDIT_INFO";
export const GET_CREDIT_INFO_SUCCEED = "GET_CREDIT_INFO_SUCCEED";
export const GET_CREDIT_INFO_FAILURE = "GET_CREDIT_INFO_FAILURE";

export const GET_UNLOCKED_INFO = "GET_UNLOCKED_INFO";
export const GET_UNLOCKED_INFO_SUCCEED = "GET_UNLOCKED_INFO_SUCCEED";
export const SET_UNLOCKED = "SET_UNLOCKED";
export const GET_UNLOCKED_INFO_FAILURE = "GET_UNLOCKED_INFO_FAILURE";

// Subscription Plans
export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS";
export const GET_SUBSCRIPTION_PLANS_SUCCESS = "GET_SUBSCRIPTION_PLANS_SUCCESS";
export const GET_SUBSCRIPTION_PLANS_FAILURE = "GET_SUBSCRIPTION_PLANS_FAILURE";

export const UNLOCK_CAR = "UNLOCK_CAR";
export const UNLOCK_CAR_SUCCESS = "UNLOCK_CAR_SUCCESS";
export const UNLOCK_CAR_FAILURE = "UNLOCK_CAR_FAILURE";

// export const DECRESE_CREDIT = "DECRESE_CREDIT";
// export const REDUCE_CREDIT = "REDUCE_CREDIT";

export const SUBSCRIBE_PLAN = "SUBSCRIBE_PLAN";
export const SUBSCRIBE_PLAN_SUCCESS = "SUBSCRIBE_PLAN_SUCCESS";
export const SUBSCRIBE_PLAN_FAILURE = "SUBSCRIBE_PLAN_FAILURE";

export const ADD_CREDITS = "ADD_CREDITS";
export const ADD_CREDITS_SUCCESS = "ADD_CREDITS_SUCCESS";
export const ADD_CREDITS_FAILURE = "ADD_CREDITS_FAILURE";

// DEALERSHIP

export const CALL_DEALERSHIP_ANONYMOUSLY = "CALL_DEALERSHIP_ANONYMOUSLY";
export const CALL_DEALERSHIP_ANONYMOUSLY_SUCCESS =
  "CALL_DEALERSHIP_ANONYMOUSLY_SUCCESS";
export const CALL_DEALERSHIP_ANONYMOUSLY_FAILURE =
  "CALL_DEALERSHIP_ANONYMOUSLY_FAILURE";

// PAYMENT CONSTANTS

export const CREATE_SESSION = "CREATE_SESSION";
export const CREATE_SESSION_SUCCSESS = "CREATE_SESSION_SUCCSESS";
export const CREATE_SESSION_FAILURE = "CREATE_SESSION_FAILURE";

export const CREATE_CHECKOUT_SESSION = "CREATE_CHECKOUT_SESSION";
export const CREATE_CHECKOUT_SESSION_SUCCSESS =
  "CREATE_CHECKOUT_SESSION_SUCCSESS";
export const CREATE_CHECKOUT_SESSION_FAILURE =
  "CREATE_CHECKOUT_SESSION_FAILURE";

export const REQUEST_PAY = "REQUEST_PAY";
export const REQUEST_PAY_SUCCSESS = "REQUEST_PAY_SUCCSESS";
export const REQUEST_PAY_FAILURE = "REQUEST_PAY_FAILURE";

export const REQUEST_RUNNER_CALL = "REQUEST_RUNNER_CALL";
export const REQUEST_RUNNER_CALL_SUCCSESS = "REQUEST_RUNNER_CALL_SUCCSESS";
export const REQUEST_RUNNER_CALL_FAILURE = "REQUEST_RUNNER_CALL_FAILURE";

export const REQUEST_WHATSAPPMESSAGE = "REQUEST_WHATSAPPMESSAGE";
export const REQUEST_WHATSAPPMESSAGE_SUCCSESS =
  "REQUEST_WHATSAPPMESSAGE_SUCCSESS";
export const REQUEST_WHATSAPPMESSAGE_FAILURE =
  "REQUEST_WHATSAPPMESSAGE_FAILURE";

export const UPDATE_PAYMENT_RESULTS = "UPDATE_PAYMENT_RESULTS";
export const UPDATE_PAYMENT_RESULTS_SUCCESS = "UPDATE_PAYMENT_RESULTS_SUCCESS";
export const UPDATE_PAYMENT_RESULTS_FAILURE = "UPDATE_PAYMENT_RESULTS_FAILURE";

// METRICS CONSTANTS

export const LOG_CONFERENCE_CALL = "LOG_CONFERENCE_CALL";
export const LOG_VDP_VIEW = "LOG_VDP_VIEW";
export const LOG_CAR_CARD_VIEW = "LOG_CAR_CARD_VIEW";

// ERRORS CONSTANTS

export const ERROR_LOG = "ERROR_LOG";
export const ERROR_LOG_SUCCEED = "ERROR_LOG_SUCCEED";
export const ERROR_LOG_FAILURE = "ERROR_LOG_FAILURE";

export const SUBMIT_SELL_MY_CAR_DATA = "SUBMIT_SELL_MY_CAR_DATA";
export const SUBMIT_SELL_MY_CAR_DATA_SUCCESS =
  "SUBMIT_SELL_MY_CAR_DATA_SUCCEED";
export const SUBMIT_SELL_MY_CAR_DATA_FAILURE =
  "SUBMIT_SELL_MY_CAR_DATA_FAILURE";

export const UNLOCKED_PART = "UNLOCKED_PART";
export const UNLOCKED_PART_SUCCEED = "UNLOCKED_PART_SUCCEED";
export const UNLOCKED_PART_FAILURE = "UNLOCKED_PART_FAILURE";

export const CLEAR_UNLOCKED_PART = "CLEAR_UNLOCKED_PART";

export const LOG_VISITOR_VIEW = "LOG_VISITOR_VIEW";
export const LOG_VISITOR_VIEW_SUCCEED = "LOG_VISITOR_VIEW_SUCCEED";
export const LOG_VISITOR_VIEW_FAILURE = "LOG_VISITOR_VIEW_FAILURE";

// TIMESLOTS CONSTANTS

export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_REQUEST_SUCCEED = "GET_CITIES_REQUEST_SUCCEED";
export const GET_CITIES_REQUEST_FAILURE = "GET_CITIES_REQUEST_FAILURE";

export const GET_AREAS_REQUEST = "GET_AREAS_REQUEST";
export const GET_AREAS_REQUEST_SUCCEED = "GET_AREAS_REQUEST_SUCCEED";
export const GET_AREAS_REQUEST_FAILURE = "GET_AREAS_REQUEST_FAILURE";

export const GET_MAKE_REQUEST = "GET_MAKE_REQUEST";
export const GET_MAKE_REQUEST_SUCCEED = "GET_MAKE_REQUEST_SUCCEED";
export const GET_MAKE_REQUEST_FAILURE = "GET_MAKE_REQUEST_FAILURE";

export const GET_MODELS_REQUEST = "GET_MODELS_REQUEST";
export const GET_MODELS_REQUEST_SUCCEED = "GET_MODELS_REQUEST_SUCCEED";
export const GET_MODELS_REQUEST_FAILURE = "GET_MODELS_REQUEST_FAILURE";

export const GET_AVAILABLE_APPOINTMENTS = "GET_AVAILABLE_APPOINTMENTS";
export const GET_AVAILABLE_APPOINTMENTS_SUCCEED =
  "GET_AVAILABLE_APPOINTMENTS_SUCCEED";
export const GET_AVAILABLE_APPOINTMENTS_FAILURE =
  "GET_AVAILABLE_APPOINTMENTS_FAILURE";

export const GET_YEARS = "GET_YEARS";
export const GET_YEARS_SUCCEED = "GET_YEARS_SUCCEED";
export const GET_YEARS_FAILURE = "GET_YEARS_FAILURE";

export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const ADD_APPOINTMENT_SUCCEED = "ADD_APPOINTMENT_SUCCEED";
export const ADD_APPOINTMENT_FAILURE = "ADD_APPOINTMENT_FAILURE";

export const GET_AVAILABLE_TIMESLOTS = "GET_AVAILABLE_TIMESLOTS";

// ???

export const APPROVE_QC_CAR_REQUEST = "APPROVE_QC_CAR_REQUEST";
export const APPROVE_QC_CAR_REQUEST_SUCCEED = "APPROVE_QC_CAR_REQUEST_SUCCEED";
export const APPROVE_QC_CAR_REQUEST_FAILURE = "APPROVE_QC_CAR_REQUEST_FAILURE";

// PACKAGES CONSTANTS

export const GET_INSPECTION_PACKAGES = "GET_INSPECTION_PACKAGES";
export const GET_INSPECTION_PACKAGES_SUCCEED =
  "GET_INSPECTION_PACKAGES_SUCCEED";
export const GET_INSPECTION_PACKAGES_FAILURE =
  "FETCH_INSPECTION_PACKAGES_FAILURE";

export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT";
export const BOOK_APPOINTMENT_SUCCEED = "BOOK_APPOINTMENT_SUCCEED";
export const BOOK_APPOINTMENT_FAILURE = "FETCH_INSPECTION_PACKAGES_FAILURE";
