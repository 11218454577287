import {
  FETCH_TOKEN_REQUEST,
  TOKEN_REMOVE_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  RESET_PASSWORD_CONFIRM,
  // ACTIVATE_ACCOUNT_REQUEST,
  FETCH_REFRESH_TOKEN_REQUEST,
  FETCH_REFRESH_TOKEN_REQUEST_SUCCEED,
  UPDATE_KEEP_ME_LOGGED_IN,
  CHECK_UNIQUE_PHONE,
  SEND_OTP,
  VALIDATE_OTP,
  SIGNUP_NEW_USER,
  RESET_PASSWORD_REQUEST,
  CHECK_PASSWORD,
  UPDATE_ACCOUNT_INFO,
  GET_CREDIT_INFO,
  GET_UNLOCKED_INFO,
  UNLOCK_CAR,
  SUBSCRIBE_PLAN,
  ADD_CREDITS,
  ERROR_LOG,
  SUBMIT_SELL_MY_CAR_DATA,
} from "../constants";

export function getToken(credentials) {
  return {
    type: FETCH_TOKEN_REQUEST,
    payload: credentials,
  };
}

export function getRefreshToken() {
  return {
    type: FETCH_REFRESH_TOKEN_REQUEST,
  };
}

export function updateToken(payload) {
  return {
    type: FETCH_REFRESH_TOKEN_REQUEST_SUCCEED,
    payload: payload,
  };
}

export function updateKeepMeLoggedIn(payload) {
  return {
    type: UPDATE_KEEP_ME_LOGGED_IN,
    payload: payload,
  };
}

export function forgotPassword(payload) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: payload,
  };
}

export function confirmReset(payload) {
  return {
    type: RESET_PASSWORD_CONFIRM,
    payload,
  };
}

// export function activateAccount(payload) {
//   return {
//     type: ACTIVATE_ACCOUNT_REQUEST,
//     payload,
//   };
// }

export function logout() {
  return {
    type: TOKEN_REMOVE_REQUEST,
  };
}

export function updateAccountInfo(payload) {
  return {
    type: UPDATE_ACCOUNT_INFO,
    payload: payload,
  };
}

export function checkUniquePhone(payload) {
  return {
    type: CHECK_UNIQUE_PHONE,
    payload: payload,
  };
} ///get phone

///
export function signUpNewUser(payload) {
  return {
    type: SIGNUP_NEW_USER,
    payload: payload,
  };
}

// SEND_OTP SIGNUP_USER
export function sendOTP(payload) {
  return {
    type: SEND_OTP,
    payload: payload,
  };
}

// VALIDATE_OTP
export function validateOTP(payload) {
  return {
    type: VALIDATE_OTP,
    payload: payload,
  };
}

// TEST
export function resetPassword(payload) {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: payload,
  };
}
export function checkPassword(payload) {
  return {
    type: CHECK_PASSWORD,
    payload: payload,
  };
}
export function getCreditInfo(payload) {
  return {
    type: GET_CREDIT_INFO,
    payload: payload,
  };
}

export function getUnlockedInfo(payload) {
  return {
    type: GET_UNLOCKED_INFO,
    payload: payload,
  };
}

export function unlockCar(payload) {
  return {
    type: UNLOCK_CAR,
    payload: payload,
  };
}

export function subscribePlan(payload) {
  return {
    type: SUBSCRIBE_PLAN,
    payload: payload,
  };
}

export function addCredits(payload) {
  return {
    type: ADD_CREDITS,
    payload: payload,
  };
}

export function logError(payload) {
  return {
    type: ERROR_LOG,
    payload: payload,
  };
}

export function submitSellMyCarData(payload) {
  return {
    type: SUBMIT_SELL_MY_CAR_DATA,
    payload: payload,
  };
}
