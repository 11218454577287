import { createReducer } from "../createReducerFunc";
import {
  CLEAR_NEW_CARS,
  CLEAR_REMOVED_CARS,
  ADD_CAR,
  REMOVE_CAR,
  SOLD_CAR,
  CLEAR_SOLD_CARS,
  FETCH_PAGE_TIME_STAMP_SUCCEED,
  FETCH_UNSEEN_NOTIFICATIONS_SUCCEED,
  // SOLD_APPOINTMENT,
  // ELAPSED_APPOINTMENT,
  // GET_SOLD_APPOINTMENTS_SUCCESS,
  // RESET_SOLD_APPOINTMENTS_SUCCESS,
  TOKEN_REMOVE_REQUEST,
  // SET_ATTENDED_COUNT_SUCCESS,
} from "../constants";

const initialState = {
  data: {},
  removedRequests: [],
  removedCars: [],
  incReqStamp: "",
  carListStamp: "",
  unseenCarListings: 0,
  working: false,
  sold: [], // is a websocket state only (there is no get soldCars saga thus it is completely reset after signout)
  soldAppointments: 0,
  elapsedAppointments: 0,
};

export default createReducer(initialState, {
  [ADD_CAR]: (state, payload) => {
    return Object.assign({}, state, {
      data: payload,
      unseenCarListings: state.unseenCarListings + 1,
    });
  },
  [REMOVE_CAR]: (state, payload) => {
    let removed_cars = state.removedCars;
    removed_cars.push(payload.instance);
    let removed = state.unseenCarListings - 1;

    return Object.assign({}, state, {
      data: payload,
      unseenCarListings: removed,
      removedCars: removed_cars,
    });
  },
  [SOLD_CAR]: (state, payload) => {
    let soldArray = state.sold;
    soldArray.push(payload.instance);

    return Object.assign({}, state, {
      sold: [...new Set([...soldArray])],
    });
  },
  // [SOLD_APPOINTMENT]: (state, payload) => {
  //   return Object.assign({}, state, {
  //     soldAppointments: payload.count,
  //   });
  // },
  // [ELAPSED_APPOINTMENT]: (state, payload) => {
  //   return Object.assign({}, state, {
  //     elapsedAppointments: payload.count,
  //   });
  // },
  // [SET_ATTENDED_COUNT_SUCCESS]: (state, payload) => {
  //   return Object.assign({}, state, {
  //     elapsedAppointments: state.elapsedAppointments - 1,
  //   });
  // },
  [CLEAR_SOLD_CARS]: (state) => {
    return Object.assign({}, state, {
      sold: [],
    });
  },
  [CLEAR_NEW_CARS]: (state) => {
    return Object.assign({}, state, {
      unseenCarListings: 0,
    });
  },
  [CLEAR_REMOVED_CARS]: (state) => {
    return Object.assign({}, state, {
      removedCars: [],
    });
  },
  [FETCH_PAGE_TIME_STAMP_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      //  new state for stamps
      incReqStamp: payload["inc_req_stamp"],
      carListStamp: payload["car_list_stamp"],
    });
  },
  [FETCH_UNSEEN_NOTIFICATIONS_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      unseenIncRequests: payload.unseen_incoming_listings,
      unseenCarListings: payload.unseen_cars,
    });
  },
  // [GET_SOLD_APPOINTMENTS_SUCCESS]: (state, payload) => {
  //   return Object.assign({}, state, {
  //     soldAppointments: payload.results[0].sold_appointments ?? 0,
  //     elapsedAppointments: payload.results[0].number_of_elapsed ?? 0,
  //   });
  // },
  // [RESET_SOLD_APPOINTMENTS_SUCCESS]: (state, payload) => {
  //   return Object.assign({}, state, {
  //     soldAppointments: 0,
  //   });
  // },
  [TOKEN_REMOVE_REQUEST]: () => {
    return Object.assign({}, initialState);
  },
});
