export default {
  text: {
    isMulti: false,
  },
  date: {
    isMulti: false,
  },
  num: {
    isMulti: false,
  },
  rb: {
    isMulti: false,
  },
  cb: {
    isMulti: true,
  },
  dd: {
    isMulti: false,
  },
  dda: {
    isMulti: false,
  },
  ddm: {
    isMulti: true,
  },
  ddma: {
    isMulti: true,
  },
  img: {
    isMulti: false,
  },
  mimg: {
    isMulti: true,
  },
  read: {
    isMulti: false,
  },
};
