import { put, takeLatest, takeEvery } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_FAILURE,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
} from "../constants";

import API_URLS from "../../api";

export function* listPlans(payload) {
  try {
    let data = {
      url: API_URLS().SUBSCRIPTION_PLANS.LIST,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: GET_SUBSCRIPTION_PLANS_SUCCESS,
      payload: response.results,
    });
    payload.payload.callback(response.results);
  } catch (e) {
    yield put({ type: GET_SUBSCRIPTION_PLANS_FAILURE, error: new Error(e) });
  }
}

export function* listPlansWatcher() {
  yield takeEvery(GET_SUBSCRIPTION_PLANS, listPlans);
}
