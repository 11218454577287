import { TOGGLE_PROMOTION_DISABLED } from "../constants";
import { createReducer } from "../createReducerFunc";

const initialState = {
  promotionDisabled: false,
};

export default createReducer(initialState, {
  [TOGGLE_PROMOTION_DISABLED]: (state, payload) => {
    return Object.assign({}, state, {
      promotionDisabled: payload,
    });
  },
});
