import { put, takeLatest, takeEvery } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import API_URLS from "../../api";
import {
  DEALER_JOIN_US,
  TOGGLE_NOTIFICATION_BAR,
  BECOME_AN_OFFICER,
  LOG_VISITOR_VIEW,
} from "../constants";
export function* joinUsDealer(action) {
  try {
    let data = {
      url: API_URLS().ACCOUNTS.ACCOUNT,
      method: "POST",
      body: {
        first_name: action.payload.firstname,
        last_name: action.payload.lastName,
        phone_number: action.payload.phone,
        dealership_name: action.payload.dealername,
        city: action.payload.city,
      },
    };
    const response = yield requestAPI(data);
    action.payload.callback(response);
  } catch (e) {
    console.log("Err", e);
  }
}

export function* joinUsDealerWatcher() {
  yield takeLatest(DEALER_JOIN_US, joinUsDealer);
}

export function* becomeAnOfficer(action) {
  try {
    let data = {
      url: API_URLS().ACCOUNTS.BECOME_AN_OFFICER,
      method: "POST",
      body: {
        first_name: action.payload.firstName,
        last_name: action.payload.lastName,
        phone_number: action.payload.phone,
      },
    };
    const response = yield requestAPI(data);
    action.payload.callback(response);
  } catch (e) {
    console.log(e);
  }
}

export function* becomeAnOfficerWatcher() {
  yield takeLatest(BECOME_AN_OFFICER, becomeAnOfficer);
}

export function* logVisitorView(action) {
  try {
    let data = {
      url: API_URLS().MARKETING.VISITOR,
      method: "POST",
      body: action.payload,
    };
    const response = yield requestAPI(data);
  } catch (e) {
    console.log(e, "logvisitorError");
  }
}

export function* logVisitorViewWatcher() {
  yield takeEvery(LOG_VISITOR_VIEW, logVisitorView);
}
