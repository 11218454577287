import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Dashboard from "./Dashboard";
import React, { Suspense } from "react";
import ErrorBoundary from "../components/ErrorBoundary";

const NotificationWrapper = React.lazy(() =>
  import("../wrappers/Notifications")
);

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading</div>}>
          <NotificationWrapper />
        </Suspense>
        <Switch>
          <Route path={"/"}>
            <Dashboard />
          </Route>

          {/* route to operations app  */}
          {/* <Route
            exact
            path="/redirect-operations"
            component={() => {
              window.location.href = "https://operations.markabte.com/";
              return null;
            }}
          /> */}

          {/* route to dealers app */}
          {/* <Route
            exact
            path="/redirect-dealers"
            component={() => {
              window.location.href = "https://dealers.markabte.com/";
              return null;
            }}
          /> */}
        </Switch>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
