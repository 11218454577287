import { createReducer } from "../createReducerFunc";
import {
  FETCH_FIELDS_REQUEST_SUCCEED,
  FETCH_CAR_FORMS_REQUEST_SUCCEED,
  UPDATE_ANSWERS,
  UPDATE_FOEMFIELDS,
  UPDATE_HIDDEN,
  UPDATE_POINTS,
  FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED,
  FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED,
} from "../constants";
import fieldTypes from "../../helpers/fields-types";

const initialState = {
  answers: {},
  hidden: {},
  formFields: [],
  points: {},
};

export default createReducer(initialState, {
  [FETCH_FIELDS_REQUEST_SUCCEED]: (state, payload) => {
    let defaultAnswers = {};
    let defaultHidden = {};
    let fields = payload;
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      defaultHidden[field.id.toString()] = false;
      if (field.options) {
        let options = field.options;
        let defaultAnswer = options.find((el) => el.default);
        if (defaultAnswer) {
          if (fieldTypes[field.type].isMulti) {
            defaultAnswers[field.id.toString()] = [
              {
                id: defaultAnswer.id.toString(),
                field: field,
                option: defaultAnswer,
              },
            ];
          } else {
            defaultAnswers[field.id.toString()] = {
              id: defaultAnswer.id.toString(),
              field: field,
              option: defaultAnswer,
            };
          }
        }
      }
    }
    return Object.assign({}, state, {
      formFields: payload,
      answers: defaultAnswers,
      hidden: defaultHidden,
    });
  },

  [FETCH_CAR_FORMS_REQUEST_SUCCEED]: (state, payload) => {
    if (payload.length > 0) {
      let answers = payload[0].form_answers;
      let hidden = payload[0].form_hidden;
      return Object.assign({}, state, {
        answers: { ...state.answers, ...answers },
        hidden: { ...state.hidden, ...hidden },
      });
    }
    return state;
  },
  [UPDATE_ANSWERS]: (state, payload) => {
    return Object.assign({}, state, {
      answers: payload,
    });
  },
  [UPDATE_FOEMFIELDS]: (state, payload) => {
    return Object.assign({}, state, {
      formFields: payload,
    });
  },

  [UPDATE_HIDDEN]: (state, payload) => {
    return Object.assign({}, state, {
      hidden: payload,
    });
  },
  [UPDATE_POINTS]: (state, payload) => {
    return Object.assign({}, state, {
      points: payload,
    });
  },
  [FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED]: (state, payload) => {
    // console.log("aaaaaaaaaaaaaaaaaaaaaa", payload.first);
    if (payload.first == "first") {
      return Object.assign({}, state, {
        points: { ...state.points, ...payload.points },
      });
    }
    if (payload.first) {
      return Object.assign({}, state, {
        points: { ...payload.points },
      });
    } else {
      return Object.assign({}, state, {
        points: { ...state.points, ...payload.points },
      });
    }
  },
  [FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED]: (state, payload) => {
    let defaultAnswers = {};
    let defaultHidden = {};
    let fields = payload;
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (Object.keys(state.answers).includes(field.id)) {
        ///// here
        defaultHidden[field.id.toString()] = false;
        if (field.options) {
          let options = field.options;
          let defaultAnswer = options.find((el) => el.default);
          if (defaultAnswer) {
            if (fieldTypes[field.type].isMulti) {
              defaultAnswers[field.id.toString()] = [
                {
                  id: defaultAnswer.id.toString(),
                  field: field,
                  option: defaultAnswer,
                },
              ];
            } else {
              defaultAnswers[field.id.toString()] = {
                id: defaultAnswer.id.toString(),
                field: field,
                option: defaultAnswer,
              };
            }
          }
        }
      }
    }
    return Object.assign({}, state, {
      fetchingFields: false,
      formFields: [...state.formFields, ...payload],
      answers: { ...state.answers, ...defaultAnswers },
      hidden: { ...state.hidden, ...defaultHidden },
    });
  },
});
