import { put, takeLatest, takeEvery } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  //   GET_APPOINTMENT_INTERVAL,
  //   GET_APPOINTMENT_INTERVAL_SUCCESS,
  //   GET_APPOINTMENT_INTERVAL_FAILURE,
  CALL_DEALERSHIP_ANONYMOUSLY,
  CALL_DEALERSHIP_ANONYMOUSLY_SUCCESS,
  CALL_DEALERSHIP_ANONYMOUSLY_FAILURE,
  GET_DEALER_HOURS,
  GET_DEALER_HOURS_SUCCESS,
  GET_DEALER_HOURS_FAILURE,
  REQUEST_RUNNER_CALL,
  TOGGLE_NOTIFICATION_BAR,
  REQUEST_RUNNER_CALL_SUCCSESS,
  REQUEST_WHATSAPPMESSAGE,
  REQUEST_WHATSAPPMESSAGE_SUCCSESS,
  REQUEST_WHATSAPPMESSAGE_FAILURE,
  SUBMIT_SELL_MY_CAR_DATA_SUCCESS,
  SUBMIT_SELL_MY_CAR_DATA_FAILURE,
  SUBMIT_SELL_MY_CAR_DATA,
} from "../constants";

import API_URLS from "../../api";
// import { imageTimeStamp } from "../../helpers/functions";
// import uploadImage, { uploads3File } from "../../helpers/aws";

// export function* getApptInterval(action) {
//   try {
//     let data = {
//       url: API_URLS(action.payload.dealershipId).DEALERSHIP
//         .GET_APPOINTMENT_INTERVAL,
//       method: "GET",
//     };
//     const response = yield requestAPI(data);

//     // yield put({ type: GET_APPOINTMENT_INTERVAL_SUCCESS, payload: response });
//     action.payload.callback(response);
//   } catch (e) {
//     yield put({ type: GET_APPOINTMENT_INTERVAL_FAILURE, error: new Error(e) });
//   }
// }

// export function* getApptIntervalWatcher() {
//   yield takeEvery(GET_APPOINTMENT_INTERVAL, getApptInterval);
// }

export function* callDealershipAnonymously(action) {
  try {
    const { userPhoneNumber, dealershipPhoneNumber } = action.payload;
    let data = {
      url: API_URLS().DEALERSHIP.CALL_DEALERSHIP_ANONYMOUSLY,
      method: "POST",
      body: { userPhoneNumber, dealershipPhoneNumber },
    };
    const response = yield requestAPI(data);

    yield put({ type: CALL_DEALERSHIP_ANONYMOUSLY_SUCCESS, payload: response });
    action.payload.callback();
  } catch (e) {
    yield put({
      type: CALL_DEALERSHIP_ANONYMOUSLY_FAILURE,
      error: new Error(e),
    });
  }
}

export function* callDealershipAnonymouslyWatcher() {
  yield takeEvery(CALL_DEALERSHIP_ANONYMOUSLY, callDealershipAnonymously);
}

export function* requestRunnerCall(action) {
  try {
    let data = {
      url: API_URLS().DEALERSHIP.REQUEST_RUNNER_CALL,
      method: "POST",
      body: action.payload,
    };
    const response = yield requestAPI(data);
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: action.payload.message,
        variant: "success",
        open: true,
      },
    });
    yield put({
      type: REQUEST_RUNNER_CALL_SUCCSESS,
      payload: response.call_request,
    });
  } catch (e) {}
}

export function* requestRunnerCallWatcher() {
  yield takeLatest(REQUEST_RUNNER_CALL, requestRunnerCall);
}

export function* requestWhatsAppMessage(action) {
  try {
    let data = {
      url: API_URLS().DEALERSHIP.REQUEST_WHATSAPPMESSAGE,
      method: "POST",
      body: action.payload,
    };
    const response = yield requestAPI(data);
    yield put({
      type: REQUEST_WHATSAPPMESSAGE_SUCCSESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: REQUEST_WHATSAPPMESSAGE_FAILURE, error: new Error(e) });
  }
}

export function* requestWhatsAppMessageWatcher() {
  yield takeLatest(REQUEST_WHATSAPPMESSAGE, requestWhatsAppMessage);
}

export function* getDealerHours(action) {
  try {
    let data = {
      url: API_URLS(action.payload.dealershipId).DEALERSHIP.GET_DEALER_HOURS,
      method: "GET",
    };
    const response = yield requestAPI(data);

    yield put({ type: GET_DEALER_HOURS_SUCCESS, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: GET_DEALER_HOURS_FAILURE, error: new Error(e) });
  }
}

export function* getDealerHoursWatcher() {
  yield takeEvery(GET_DEALER_HOURS, getDealerHours);
}

export function* submitSellMyCarData(action) {
  try {
    let data = {
      url: API_URLS().DEALERSHIP.SELL_REQUEST,
      method: "POST",
      body: action.payload.data,
    };
    const response = yield requestAPI(data);
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: action.payload.message,
        variant: "success",
        open: true,
      },
    });
    action.payload.callback(response);
    // yield put({ type: SUBMIT_SELL_MY_CAR_DATA_SUCCESS, payload: response });
    // action.payload.callback(response);
  } catch (e) {
    action.payload.callback();
    yield put({ type: SUBMIT_SELL_MY_CAR_DATA_FAILURE, error: new Error(e) });
  }
}

export function* submitSellMyCarDataWatcher() {
  yield takeEvery(SUBMIT_SELL_MY_CAR_DATA, submitSellMyCarData);
}
