import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import colors from "./colors";
import breakpoints from "./breakpoints";

const theme = createMuiTheme({
  direction: localStorage.getItem("language") === "en" ? "ltr" : "rtl", // arabic is the condition because at first entry or in incognito mode the language is not stored in local storage yet so it will default to the else (: 'ltr') and then set 'en' in local storage if you reverse them you will have styling problems on first entry and incognito
  palette: {
    danger: {
      main: colors.danger.first,
      contrastText: "#fff",
    },
    primary: {
      main: colors.primary.first,
      contrastText: "#fff",
    },
    secondary: {
      main: colors.primary.second,
      contrastText: "#fff",
    },
    background: {
      default: colors.neutral.grey,
    },
  },
  typography: {
    fontFamily:
      localStorage.getItem("language") === "en"
        ? "'Poppins', sans-serif"
        : "'Cairo', sans-serif;",
  },
  breakpoints: breakpoints,
});

export default responsiveFontSizes(theme);
