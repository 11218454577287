import { createReducer } from "../createReducerFunc";

import {
  GET_CITIES_REQUEST,
  GET_CITIES_REQUEST_SUCCEED,
  GET_AREAS_REQUEST,
  GET_AREAS_REQUEST_SUCCEED,
  GET_MAKE_REQUEST,
  GET_MAKE_REQUEST_SUCCEED,
  GET_MODELS_REQUEST,
  GET_MODELS_REQUEST_SUCCEED,
  GET_AVAILABLE_APPOINTMENTS,
  GET_AVAILABLE_APPOINTMENTS_SUCCEED,
  GET_YEARS,
  GET_YEARS_SUCCEED,
  GET_INSPECTION_PACKAGES_SUCCEED,
} from "../constants";

const initialState = {
  cities: [],
  areas: [],
  make: [],
  models: [],
  available_appointments: [],
  years: [],
  inspectionPackages: [],
};

export default createReducer(initialState, {
  [GET_CITIES_REQUEST]: (state, payload) => {
    return Object.assign({}, state, {
      cities: payload,
    });
  },

  [GET_CITIES_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      cities: payload.results,
    });
  },

  [GET_AREAS_REQUEST]: (state, payload) => {
    return Object.assign({}, state, {
      areas: payload,
    });
  },

  [GET_AREAS_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      areas: payload.results,
    });
  },

  [GET_MAKE_REQUEST]: (state, payload) => {
    return Object.assign({}, state, {
      make: payload,
    });
  },

  [GET_MAKE_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      make: payload.results,
    });
  },

  [GET_MODELS_REQUEST]: (state, payload) => {
    return Object.assign({}, state, {
      models: payload,
    });
  },

  [GET_MODELS_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      models: payload.results,
    });
  },
  [GET_AVAILABLE_APPOINTMENTS]: (state, payload) => {
    return Object.assign({}, state, {
      available_appointments: payload,
    });
  },

  [GET_AVAILABLE_APPOINTMENTS_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      available_appointments: payload,
    });
  },

  [GET_YEARS]: (state, payload) => {
    return Object.assign({}, state, {
      years: payload,
    });
  },

  [GET_YEARS_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      years: payload[0].options,
    });
  },

  [GET_INSPECTION_PACKAGES_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      inspectionPackages: payload,
    });
  },
});
